/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable object-shorthand */
/* eslint-disable one-var */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import {
  AlertController,
  AnimationController,
  MenuController,
  ModalController,
  Platform,
} from '@ionic/angular';

// import { SplashScreen } from '@ionic-native/splash-screen/ngx';
// import { HeaderColor } from '@ionic-native/header-color/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { HeaderColor } from '@ionic-native/header-color/ngx';

import { AuthenticationService } from './services/apis/authentication/authentication.service';
import { ToastService } from './services/useful/toast/toast.service';
import { UserService } from './services/apis/user/user.service';
import { environment } from 'src/environments/environment';
import { ProgressBarService } from './services/useful/loading/progress-bar/progress-bar.service';
import * as $ from 'jquery';
import { ModalBoletoPage } from './pages/user/modal/modal-boleto/modal-boleto.page';
import { ModalNotificationPage } from './pages/user/modal/modal-notification/modal-notification.page';
// import { initializeApp } from 'firebase/app';
import firebase from 'firebase/app';
import { ModalIrpfPage } from './pages/user/modal/modal-irpf/modal-irpf.page';
import { VersionService } from './services/apis/version/version.service';
import { StatusBar } from '@ionic-native/status-bar';

declare global {
  interface Window {
    ActiveXObject: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private logo: any = environment.logo;
  user: any = {
    multiplasAdesoes: null,
  };
  menuValue: any = 'dashboard';
  isCPF = true;
  public ir: any = [];
  public notification = [];
  public alertAdesao: any;
  public alertInputs: any[] = [];
  public appfire: any;
  public hash: any
  public logged = false;

  constructor(
    private platform: Platform,
    // private splashScreen: SplashScreen,
    // private headerColor: HeaderColor,
    private authenticationService: AuthenticationService,
    private router: Router,
    private menu: MenuController,
    private toast: ToastService,
    public alertController: AlertController,
    private userService: UserService,
    private progressBar: ProgressBarService,
    private transfer: FileTransfer,
    private file: File,
    public fileOpener: FileOpener,
    private iab: InAppBrowser,
    private headerColor: HeaderColor,
    public modalController: ModalController,
    public animationCtrl: AnimationController,
    private versionServer: VersionService,
  ) {
    this.hash = location.hash
    this.InitializeApp();
    // console.log(this.authenticationService.criptografar('10012207144'))
  }

  async InitializeApp() {
    this.platform.ready().then(() => {
      this.headerColor.tint('#3c6070');
      // this.splashScreen.hide();
      this.authenticationService.CreateToken().subscribe(
        async (res) => {
          if (res.sucesso === true) {
            await environment.storage.setItem(
              'ACCESSTOKENBASE',
              JSON.stringify(res.accessToken)
            );
          }
        },
        (error) => { }
      );
      this.menu.swipeGesture(false);
      this.Authentication();
      this.AuthenticationUser();
      this.appfire = firebase.initializeApp(environment.firebaseConfig);
      this.versionServer.CheckUpdate();
    });
  }

  async Authentication() {
    this.authenticationService.authenticationState.subscribe(async (state) => {
      if (state) {
        this.logged = false;
        StatusBar.backgroundColorByHexString('#ffffff');
        this.router.navigate(['auth']);
        // this.router.navigate(['user', 'contract']);
        // this.router.navigate(['user', 'courses'])
      }
    });
  }

  async AuthenticationUser() {
    this.authenticationService.authenticationStateUser.subscribe(
      async (state) => {
        if (state) {
          this.logged = true;
          StatusBar.backgroundColorByHexString('#00a7ba');
          this.user = await JSON.parse(environment.storage.getItem('USER'));
          // this.setTimeout(); 
          let navigationExtras: NavigationExtras = {
            queryParams: {
              hash: this.hash,
            },
            queryParamsHandling: this.hash ? 'merge' : 'preserve',
          };
          this.router.navigate(['presentation'], navigationExtras);
          $(`#${this.menuValue}`).addClass('active');
          this.ValidCPFCNPJ();
          setTimeout(() => {
            this.GetUserNotification();
          }, 4000);
        }
      }
    );
  }

  setTimeout() {
    setTimeout(() => {
      this.authenticationService.Logout();
      const toast = {
        message: 'Sua sessão expirou. Faça login para acessar novamente!',
        color: 'warning',
      };
      this.toast.presentToastWithOptions(toast);
    }, environment.expires);
  }

  CloseMenu() {
    this.menu.close('left');
  }

  navegatePage(page) {
    if (page) {
      this.menuValue = page;
      $('.itemMenu').removeClass('active');
      $(`.btnTab`).removeClass('selected');
      $(`#${page}`).addClass('active');
      switch (page) {
        case 'dashboard':
          $(`.tabdashboard`).addClass('selected');
          this.router.navigate(['user', 'dashboard']);
          break;

        case 'financeiro':
          this.router.navigate(['user', 'financial']);
          break;

        case 'contrato':
          $(`.tabcontrato`).addClass('selected');
          this.router.navigate(['user', 'contract']);
          break;

        case 'contatos':
          this.router.navigate(['user', 'contacts']);
          break;

        case 'dados':
          this.router.navigate(['user', 'your-data']);
          break;

        case 'coparticipacao':
          $(`.tabcoparticipacao`).addClass('selected');
          this.router.navigate(['user', 'co-participation']);
          break;

        case 'redes':
          $(`.tabredes`).addClass('selected');
          this.router.navigate(['user', 'accredited-networks']);
          break;

        case 'carteirinha':
          this.router.navigate(['user', 'little-wallet']);
          break;

        default:
          break;
      }
      this.menu.close('left');
    }
  }

  async DoLogout() {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: 'Você realmente deseja <strong>sair</strong>!!!',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            this.CloseMenu();
          },
        },
        {
          text: 'Sair',
          handler: () => {
            this.menu.close('left');
            this.authenticationService.Logout();
          },
        },
      ],
    });

    await alert.present();
  }

  async ValidCPFCNPJ() {
    if (this.user.adesaoID || this.user.contratoID) {
      this.userService.GetUserInfo().subscribe(
        (res) => {
          setTimeout(async () => {
            // res = environment.userMock;
            if (res.sucesso === true) {
              if (this.user.codigo) {
                res.contrato.beneficiarioList.forEach(element => {
                  if (element.tipoBeneficiario.nome === 'Titular') {
                    if (element.cpf.length === 11) {
                      this.isCPF = true;
                    } else {
                      this.isCPF = false;
                    }
                  }
                });
              }
              if (this.user.contratoID) {
                if (res.modelo.cnpjEmpresa.length === 14) {
                  this.isCPF = false;
                } else {
                  this.isCPF = true;
                }
              }
            }
          }, 100);
        },
        (error) => {
          this.progressBar.stopProgressBar();
        }
      );
    }
  }

  async GetIRUser() {
    this.progressBar.startProgressBar();
    // this.ir = [
    //   {
    //     value: 'https://drive.google.com/file/d/19c3chiilwBYm78FBau_Jyo5afhGi8ZAl/view?usp=sharing',
    //     name: 2019,
    //     type: 'radio',
    //     label: 2019,
    //   },
    //   {
    //     value: 'https://americalatina.dint.fgv.br/sites/americalatina.dint.fgv.br/files/teste33.pdf',
    //     name: 2020,
    //     type: 'radio',
    //     label: 2020,
    //   },
    // ],
      // this.ir = [];
      this.userService.GetDemonstrativoDigitalSaudePorContrato().subscribe(
        async (res) => {
          if (res.sucesso) {
            if (this.isCPF === true) {
              if (res.demonstrativo.length > 0) {
                await res.demonstrativo.forEach((element) => {
                  this.ir.push({
                    name: element.anoReferencia,
                    type: 'radio',
                    label: element.anoReferencia,
                    value: element.linkDemonstrativo,
                  });
                });
                this.progressBar.stopProgressBar();
                this.menu.close('left');
                this.presentAlertIR(this.ir);
              } else {
                this.progressBar.stopProgressBar();
                this.menu.close('left');
                this.presentAlertNOIR();
              }
              console.log(res)
            } else {
              this.progressBar.stopProgressBar();
            }
          }
        },
        (error) => {
          this.progressBar.stopProgressBar();
        }
      );
    this.progressBar.stopProgressBar();
    this.menu.close('left');
    // this.presentAlertNOIR();
    // this.presentAlertIR(this.ir);
  }

  async presentAlertIR(ir) {
    const enterAnimation = (baseEl: any) => {
      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(baseEl.querySelector('.modal-wrapper')!)
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '1', transform: 'scale(1)' },
        ]);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(200)
        .addAnimation([wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: any) =>
      enterAnimation(baseEl).direction('reverse');

    const modal = await this.modalController.create({
      component: ModalIrpfPage,
      backdropDismiss: false,
      componentProps: {
        irpf: this.ir,
      },
      cssClass: 'modalIR',
      enterAnimation,
      leaveAnimation,
      // backdropDismiss: false,
    });
    modal.onWillDismiss().then((ev) => {
      // console.log(ev);
    });

    return await modal.present();
  }

  async presentAlertNOIR() {
    const alert = await this.alertController.create({
      header: 'IR (Imposto de Renda)',
      message: 'Usuário não possui IR para download',
      buttons: ['OK'],
    });

    await alert.present();
  }

  async GetAdesao() {
    // this.alertInputs = [];
    // await this.user.adesoes.forEach((element) => {
    //   if (element.adesaoID === this.user.adesaoID) {
    //     this.alertInputs.push({
    //       label: element.nome,
    //       type: 'radio',
    //       value: element.adesaoID,
    //       checked: true,
    //       handler: async () => {
    //         await this.alertAdesao.dismiss();
    //       },
    //     });
    //   } else {
    //     this.alertInputs.push({
    //       label: element.nome,
    //       type: 'radio',
    //       value: element.adesaoID,
    //       checked: false,
    //       handler: async (data) => {
    //         this.ChangeUser(data.value);
    //       },
    //     });
    //   }
    // });
    // this.menu.close('left');
    // this.AlertSelectAdesao();
    await this.user.contratos.forEach(element => {
      this.alertInputs.push(
        {
          label: element.convenio.produto.nome,
          type: 'radio',
          value: element.codigo,
          handler: async (data) => {
            this.ChangeUser(data.value);
          },
        },
      );
    });
    this.AlertSelectAdesao();
  }

  async AlertSelectAdesao() {
    this.alertAdesao = await this.alertController.create({
      header: 'Contrato',
      message: 'Escolha seu contrato para continuar',
      backdropDismiss: false,
      inputs: this.alertInputs,
      cssClass: 'alertAdesão',
      mode: 'ios',
    });

    await this.alertAdesao.present();
  }

  async ChangeUser(id) {
    this.user.codigo = await id;
    this.user.adesaoID = await id;
    await environment.storage.setItem('USER', JSON.stringify(this.user));
    await this.alertAdesao.dismiss();
    window.location.reload();
  }

  async segundaVia(type) {
    let title, msg;
    if (type === 'carteirinha') {
      title = 'Segunda via Carteirinha';
      msg =
        'Para solicitar segunda via da carteirinha descreva a necessidade abaixo.';
    }
    if (type === 'boleto') {
      title = 'Segunda via Boleto';
      msg =
        'Para solicitar segunda via do boleto descreva abaixo as informações do boleto como mês, ano.';
    }
    const alert = await this.alertController.create({
      cssClass: 'alertSolicitacao',
      header: title,
      message: msg,
      animated: true,
      inputs: [
        {
          name: 'descricao',
          id: 'descricao',
          type: 'textarea',
          placeholder: 'Digite aqui!!!',
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: () => {
            console.log('Confirm Cancel');
          },
        },
        {
          text: 'Solicitar',
          handler: (data) => {
            this.progressBar.startProgressBar();
            let tipoAtendimentoID;
            if (type === 'carteirinha') {
              tipoAtendimentoID = '7a9545a5-7a94-47c4-aa3b-07cb183a77e4';
            }
            if (type === 'boleto') {
              tipoAtendimentoID = '3c8ed4bd-c3eb-4e57-a993-4580986d3560';
            }
            this.userService
              .PostSegundaVia({
                descricao: data.descricao,
                tipoAtendimentoID: tipoAtendimentoID,
              })
              .subscribe(
                (res) => {
                  setTimeout(async () => {
                    let toast;
                    if (res.sucesso === true) {
                      this.menu.close('left');
                      toast = {
                        message: res.mensagem,
                        color: 'success',
                      };
                      this.progressBar.stopProgressBar();
                      this.toast.presentToast(toast);
                    } else {
                      this.menu.close('left');
                      toast = {
                        message: res.mensagem,
                        color: 'warning',
                      };
                      this.progressBar.stopProgressBar();
                      this.toast.presentToast(toast);
                    }
                  }, 500);
                },
                (error) => {
                  const toast = {
                    message: error.mensagem,
                    color: 'warning',
                  };
                  this.progressBar.stopProgressBar();
                  this.toast.presentToast(toast);
                }
              );
          },
        },
      ],
    });

    await alert.present();
  }

  async GoModalNotification() {
    this.menu.close('left');
    if (this.notification.length > 0) {
      const enterAnimation = (baseEl: any) => {
        const wrapperAnimation = this.animationCtrl
          .create()
          .addElement(baseEl.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'scale(0)' },
            { offset: 1, opacity: '1', transform: 'scale(1)' },
          ]);

        return this.animationCtrl
          .create()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(200)
          .addAnimation([wrapperAnimation]);
      };

      const leaveAnimation = (baseEl: any) =>
        enterAnimation(baseEl).direction('reverse');

      const modal = await this.modalController.create({
        component: ModalNotificationPage,
        componentProps: {
          notification: this.notification,
        },
        cssClass: 'classModalNotification',
        enterAnimation,
        leaveAnimation,
        // backdropDismiss: false,
      });

      modal.onWillDismiss().then((ev) => {
        // console.log(ev);
        if (ev.data) {
          if (ev.data.boleto) {
            this.GoModalBoleto(ev.data.boleto);
          } else {
          }
        }
      });

      return await modal.present();
    } else {
      this.presentAlertNONotification();
    }
  }

  async presentAlertNONotification() {
    const alert = await this.alertController.create({
      header: 'Notificação',
      message: 'Usuário não possui notificação',
      buttons: ['OK'],
    });

    await alert.present();
  }

  GetUserNotification() {
    this.notification = [];
    let user = JSON.parse(environment.storage.getItem('USER'));
    user.contratos.forEach(element => {
      if (user.codigo === element.codigo) {
        if (!element.entidade) {
          this.userService.GetFaturaDigitalSaudePorContrato().subscribe(
            (res) => {
              if (res.sucesso === true) {
                this.notification = [];
                res.faturas.forEach((element) => {
                  if (
                    element.statusFatura.nome === 'Emitida' ||
                    element.statusFatura.nome === 'Vencida'
                  ) {
                    this.notification.push(element);
                  }
                });
              } else { }
            },
            (error) => { }
          );
        }
        else { }
      }
    });
  }

  async GoModalBoleto(boleto) {
    this.progressBar.startProgressBar();
    const enterAnimation = (baseEl: any) => {
      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(baseEl.querySelector('.modal-wrapper')!)
        .keyframes([
          { offset: 0, opacity: '0', transform: 'scale(0)' },
          { offset: 1, opacity: '1', transform: 'scale(1)' },
        ]);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(200)
        .addAnimation([wrapperAnimation]);
    };

    const leaveAnimation = (baseEl: any) =>
      enterAnimation(baseEl).direction('reverse');
    if (boleto.codigo) {
      const bl = boleto;
      bl.codigoBarra = boleto.linhaDigitavel;
      this.progressBar.stopProgressBar();
      const modal = await this.modalController.create({
        component: ModalBoletoPage,
        cssClass: 'modalBoleto',
        enterAnimation,
        leaveAnimation,
        componentProps: {
          boleto: bl,
        },
        backdropDismiss: false,
      });

      modal.onWillDismiss().then((ev) => {
        // if (ev.data.dismissed === 'logout') {
        //   this.authService.Logout();
        // } else {
        //   // this.init = false;
        //   // this.Playlist();
        // }
      });

      return await modal.present();
    } else {
      const toast = {
        message: 'Boleto indisponível no momento!',
        color: 'light',
      };
      this.progressBar.stopProgressBar();
      this.toast.presentToastWithOptions(toast);
    }
  }
}
