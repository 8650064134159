/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { AlertController, AnimationController, ModalController, Platform } from '@ionic/angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ModalAlertPage } from 'src/app/pages/public/modal/modal-alert/modal-alert.page';
import { AuthenticationService } from '../authentication/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  public isOpen: any = false;
  public interval: any;
  public isOk = false;

  constructor(
    private httpClient: HttpClient,
    public alertController: AlertController,
    private appVersion: AppVersion,
    private plt: Platform,
    private market: Market,
    private iab: InAppBrowser,
    public modalController: ModalController,
    public animationCtrl: AnimationController,
    private authenticationService: AuthenticationService,
  ) { }

  GetVersion() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.httpClient
      .get(`${environment.apiBaseEasyPlanCorretor}/version/app/cliente`)
      .pipe(tap(async (res: any) => { }));
  }

  CheckUpdate() {
    this.GetVersion().subscribe(
      async (res) => {
        if (res)
          if (this.plt.is('android') && !this.plt.is('mobileweb')) {
            if (res.android.communication.enabled) {
              this.GoModalCommunication(res.android.communication);
            }
            else {
              this.isOk = true;
            }
            const serverVersion = res.android.current.split('.');
            const versionNumber = await this.appVersion.getVersionNumber();
            const splittedVersion = versionNumber.split('.');

            if (serverVersion[0] > splittedVersion[0]) {
              if (!this.isOpen) {
                this.presentAlert(
                  res.android.majorMsg.title,
                  res.android.majorMsg.msg,
                  res.android.majorMsg.btn
                );
                this.SetInterval();
              }
            } else if (serverVersion[1] > splittedVersion[1]) {
              if (!this.isOpen) {
                this.presentAlert(
                  res.android.majorMsg.title,
                  res.android.majorMsg.msg,
                  res.android.majorMsg.btn
                );
                this.SetInterval();
              }
            } else if (serverVersion[2] > splittedVersion[2]) {
              if (!this.isOpen) {
                this.presentAlert(
                  res.android.majorMsg.title,
                  res.android.majorMsg.msg,
                  res.android.majorMsg.btn
                );
                this.SetInterval();
              }
            } else {
              this.isOpen = false;
              clearInterval(this.interval);
            }
          } else if (this.plt.is('ios') && !this.plt.is('mobileweb')) {
            if (res.ios.communication.enabled) {
              this.GoModalCommunication(res.ios.communication);
            }
            else {
              this.isOk = true;
            }
            const serverVersion = res.ios.current.split('.');
            const versionNumber = await this.appVersion.getVersionNumber();
            const splittedVersion = versionNumber.split('.');

            if (serverVersion[0] > splittedVersion[0]) {
              if (!this.isOpen) {
                this.presentAlert(
                  res.ios.majorMsg.title,
                  res.ios.majorMsg.msg,
                  res.ios.majorMsg.btn
                );
                this.SetInterval();
              }
            } else if (serverVersion[1] > splittedVersion[1]) {
              if (!this.isOpen) {
                this.presentAlert(
                  res.ios.majorMsg.title,
                  res.ios.majorMsg.msg,
                  res.ios.majorMsg.btn
                );
                this.SetInterval();
              }
            } else if (serverVersion[2] > splittedVersion[2]) {
              if (!this.isOpen) {
                this.presentAlert(
                  res.ios.majorMsg.title,
                  res.ios.majorMsg.msg,
                  res.ios.majorMsg.btn
                );
                this.SetInterval();
              }
            } else {
              this.isOpen = false;
              clearInterval(this.interval);
            }
          } else if (this.plt.is('desktop') || this.plt.is('mobileweb')) {
            this.isOpen = false;
            clearInterval(this.interval);
            if (res.web.communication.enabled) {
              this.GoModalCommunication(res.web.communication);
            }
            else {
              this.isOk = true;
            }
            // const serverVersion = res.web.current.split('.');
            // const versionNumber = await this.appVersion.getVersionNumber();
            // const splittedVersion = versionNumber.split('.');

            // if (serverVersion[0] > splittedVersion[0]) {
            //   if (!this.isOpen) {
            //     this.presentAlert(
            //       res.web.majorMsg.title,
            //       res.web.majorMsg.msg,
            //       res.web.majorMsg.btn
            //     );
            //     this.SetInterval();
            //   }
            // } else if (serverVersion[1] > splittedVersion[1]) {
            //   if (!this.isOpen) {
            //     this.presentAlert(
            //       res.web.majorMsg.title,
            //       res.web.majorMsg.msg,
            //       res.web.majorMsg.btn
            //     );
            //     this.SetInterval();
            //   }
            // } else if (serverVersion[2] > splittedVersion[2]) {
            //   if (!this.isOpen) {
            //     this.presentAlert(
            //       res.web.majorMsg.title,
            //       res.web.majorMsg.msg,
            //       res.web.majorMsg.btn
            //     );
            //     this.SetInterval();
            //   }
            // } else {
            //   this.isOpen = false;
            //   clearInterval(this.interval);
            // }
          }
      },
      (error) => { }
    );
  }

  async presentAlert(header, message, btn) {
    const alert = await this.alertController.create({
      header,
      message,
      backdropDismiss: false,
      mode: 'ios',
      buttons: [btn],
    });

    await alert.present();
    this.isOpen = true;

    alert.onWillDismiss().then(async () => {
      this.isOpen = false;
      if (this.plt.is('android') && !this.plt.is('mobileweb')) {
        this.market.open('br.com.easyplan.app');
      } else if (this.plt.is('ios') && !this.plt.is('mobileweb')) {
        const browser = await this.iab.create(
          `itms-apps://itunes.apple.com/app/id1617898567`,
          '_blank',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
      } else if (this.plt.is('desktop') || this.plt.is('mobileweb')) {
        const browser = await this.iab.create(
          `https://www.easyplan.com.br/`,
          '_blank',
          'location=yes,closebuttoncolor=#ffffff,Navigationbuttoncolor=#3c6070,hidenavigationbuttons=yes,toolbarcolor=#3c6070,toolbartranslucent=yes,toolbarposition=top'
        );
        browser.show();
      }
    });
  }

  SetInterval() {
    this.interval = setInterval(() => {
      this.CheckUpdate();
    }, 30000);
  }

  async GoModalCommunication(val) {
    if (val.logged) {
      const enterAnimation = (baseEl: any) => {
        const wrapperAnimation = this.animationCtrl
          .create()
          .addElement(baseEl.querySelector('.modal-wrapper')!)
          .keyframes([
            { offset: 0, opacity: '0', transform: 'scale(0)' },
            { offset: 1, opacity: '1', transform: 'scale(1)' },
          ]);

        return this.animationCtrl
          .create()
          .addElement(baseEl)
          .easing('ease-out')
          .duration(250)
          .addAnimation([wrapperAnimation]);
      };

      const leaveAnimation = (baseEl: any) =>
        enterAnimation(baseEl).direction('reverse');

      const modal = await this.modalController.create({
        component: ModalAlertPage,
        backdropDismiss: false,
        componentProps: {
          communication: val,
        },
        cssClass: '',
        enterAnimation,
        leaveAnimation,
      });
      modal.onWillDismiss().then((ev) => {
        // console.log(ev);
        this.isOk = true;
      });
      return await modal.present();
    }
    this.authenticationService.authenticationState.subscribe(async (state) => {
      if (state) {
        const enterAnimation = (baseEl: any) => {
          const wrapperAnimation = this.animationCtrl
            .create()
            .addElement(baseEl.querySelector('.modal-wrapper')!)
            .keyframes([
              { offset: 0, opacity: '0', transform: 'scale(0)' },
              { offset: 1, opacity: '1', transform: 'scale(1)' },
            ]);

          return this.animationCtrl
            .create()
            .addElement(baseEl)
            .easing('ease-out')
            .duration(250)
            .addAnimation([wrapperAnimation]);
        };

        const leaveAnimation = (baseEl: any) =>
          enterAnimation(baseEl).direction('reverse');

        const modal = await this.modalController.create({
          component: ModalAlertPage,
          backdropDismiss: false,
          componentProps: {
            communication: val,
          },
          cssClass: '',
          enterAnimation,
          leaveAnimation,
        });
        modal.onWillDismiss().then((ev) => {
          // console.log(ev);
          this.isOk = true;
        });
        return await modal.present();
      }
    });
  }

  verifyOk() {
    return this.isOk;
  }
}
