/* eslint-disable max-len */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError, observable } from 'rxjs';
import * as moment from 'moment';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private httpClient: HttpClient) { }

  GetUserInfo(us?) {
    let user = us ? us : JSON.parse(environment.storage.getItem('USER'));
    // let formData = new FormData();
    // let url;
    // if (user.adesaoID) {
    //   formData.append('id', user.adesaoID);
    //   url = 'https://administradora.easyplan.com.br/AreaBeneficiario/Pagina';
    // } else {
    //   formData.append('id', user.contratoID);
    //   url = 'https://administradora.easyplan.com.br/AreaEmpresa/Dados';
    // }

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
    //     'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
    //     'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
    //   }),
    //   withCredentials: true,
    // };
    // return this.httpClient
    //   .post(`${url}`, formData, httpOptions)
    //   .pipe(tap(async (res: any) => { }));

    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/digitalsaude/contrato/procurarPorCodigo/${user.codigo}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetTokenCassi() {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${btoa(`18987107000130:199e9aa0-e030-4be6-bf11-5913641190bd`)}`
      })
    };
    return this.httpClient
      .post(
        `https://rhsso.paas.cassi.com.br/auth/realms/Colaborador/protocol/openid-connect/token`,
        `scope=resource.write+resource.read&grant_type=client_credentials`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCarteirinhaCassi(dados) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "user_key": "ab528b045541388f54f6f0c3733f272c",
        Authorization: `Bearer ${dados.token}`
      })
    };
    return this.httpClient
      .get(`https://convenio.api.paas.cassi.com.br/api/v2/cartao/listarCartaoReciprocidade?cpfs=${dados.cpf}`, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetUserCoparticipacao(page) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    formData.append('pagina', page);
    formData.append('paginacao', '-1');
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
    } else {
      formData.append('contratoID', user.contratoID);
    }
    // formData.append('adesaoID', 'fd03db58-6998-11ec-aaf4-020506faab1c');
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaBeneficiario/ListarCoparticipacao`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUserRedes(page?, size?) {
    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanClientes}/redescredenciadas/?page=${page ? page : 0}&size=${size ? size : 100}`,

      )
      .pipe(tap(async (res: any) => { }));
  }

  GetRedesSearch(especialidadeID, produtoID, produto, estadoID, cidadeID, page, size) {
    return this.httpClient
      .post(
        `${environment.apiBaseEasyPlanClientes}/redescredenciadas/search`,
        {
          especialidadeID: especialidadeID,
          produtoID: produtoID,
          produto: produto,
          estadoID: estadoID,
          cidadeID: cidadeID,
          page: page,
          size: size,
        }
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUserRedesEspecialidades(id?) {
    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanClientes}/redescredenciadas/especialidades/${id ? id : ''}`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUserRedesProdutos(id?) {
    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanClientes}/redescredenciadas/produtos/${id ? id : ''}`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUserEnderecos(page?) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    if (page !== '' && page !== undefined && page !== null) {
      formData.append('pagina', page);
    } else {
      formData.append('pagina', '0');
    }
    formData.append('paginacao', '-1');
    let url;
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
      url = 'https://administradora.easyplan.com.br/AreaBeneficiario/ListarEnderecos';
    } else {
      // formData.append('id', user.contratoID);
      formData.append('id', user.contratoID);
      url = 'https://administradora.easyplan.com.br/AreaEmpresa/dados';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(`${url}`, formData, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetUserContatos(page?, us?) {
    let user = us ? us : JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    if (page !== '' && page !== undefined && page !== null) {
      formData.append('pagina', page);
    } else {
      formData.append('pagina', '0');
    }
    formData.append('paginacao', '-1');
    let url;
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
      url = 'https://administradora.easyplan.com.br/AreaBeneficiario/ListarContatos';
    } else {
      formData.append('filtros', `empresaID.ToString().Equals("${user.empresaID}")`);
      url = 'https://administradora.easyplan.com.br/EmpresaContato/Listar';
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(`${url}`, formData, httpOptions)
      .pipe(tap(async (res: any) => { }));
  }

  GetBoletoInfo(boletoID) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    formData.append('boletoID', boletoID);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaBeneficiario/VisualizarDadosBoleto`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  PostSegundaVia(data) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
    } else {
      formData.append('contratoID', user.contratoID);
    }
    formData.append('tipoAtendimentoID', data.tipoAtendimentoID);
    formData.append('descricao', data.descricao);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaBeneficiario/NovoAtendimentoWeb`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  PostVisualizarArquivo(data) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    formData.append('id', data);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/AreaBeneficiario/VisualizarArquivo`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  ADDContact(data) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
    } else {
      formData.append('contratoID', user.contratoID);
    }
    formData.append('nome', data.nome);
    formData.append('telefone', data.telefone);
    formData.append('telefoneAlternativo', data.telefoneAlternativo);
    formData.append('email', data.email);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/BeneficiarioContato/Salvar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  EditContact(data, contato) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
    } else {
      formData.append('contratoID', user.contratoID);
    }
    formData.append('beneficiarioID', contato.beneficiarioID);
    formData.append('beneficiarioContatoID', contato.beneficiarioContatoID);
    formData.append('nome', data.nome);
    formData.append('telefone', data.telefone);
    formData.append('telefoneAlternativo', data.telefoneAlternativo);
    formData.append('email', data.email);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/BeneficiarioContato/Salvar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  DeleteContact(data) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    formData.append('id', data);
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/BeneficiarioContato/Excluir`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetUF() {
    // let user = JSON.parse(environment.storage.getItem('USER'));
    // let formData = new FormData();
    // formData.append('pagina', '0');
    // formData.append('paginacao', '-1');
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
    //     'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
    //     'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
    //   }),
    // };
    // return this.httpClient
    //   .post(
    //     `https://administradora.easyplan.com.br/Utils/ListarEstados`,
    //     formData,
    //     httpOptions
    //   )
    //   .pipe(tap(async (res: any) => { }));
    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanCorretor}/estado`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetCidade(val) {
    // let user = JSON.parse(environment.storage.getItem('USER'));
    // let formData = new FormData();
    // formData.append('pagina', '0');
    // formData.append('paginacao', '-1');
    // formData.append('filtros', `estadoID==${val}`);
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
    //     'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
    //     'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
    //   }),
    // };
    // return this.httpClient
    //   .post(
    //     `https://administradora.easyplan.com.br/Utils/ListarCidades`,
    //     formData,
    //     httpOptions
    //   )
    //   .pipe(tap(async (res: any) => { }));
    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanCorretor}/estado/${val}`,
      )
      .pipe(tap(async (res: any) => { }));
  }

  EditEndereco(data, endereco) {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let formData = new FormData();
    if (user.adesaoID) {
      formData.append('adesaoID', user.adesaoID);
    } else {
      formData.append('contratoID', user.contratoID);
    }
    formData.append('beneficiarioID', endereco.beneficiarioID);
    formData.append('beneficiarioEnderecoID', endereco.enderecoID);
    formData.append('logradouro', data.logradouro);
    formData.append('complemento', data.complemento);
    formData.append('numero', data.numero);
    formData.append('bairro', data.bairro);
    formData.append('cep', data.cep);
    formData.append('cidadeID', data.cidade);
    formData.append('principal', 'true');
    const httpOptions = {
      headers: new HttpHeaders({
        'x-token': user.token ? user.token : '0455451f-e3ca-11ed-abbf-020506faab1c04554520-e3ca-11ed-abbf-020506faab1c04554521-e3ca-11ed-abbf-020506faab1c',
        'x-api-key': 'CEF6A4F2-6B91-4797-A54D-EFA20CE51E39',
        'x-api-id': 'DAF2AD3D-4EFD-4E11-A954-670AC15F1C37',
      }),
    };
    return this.httpClient
      .post(
        `https://administradora.easyplan.com.br/BeneficiarioEndereco/Salvar`,
        formData,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetContratoDigitalSaudePorCPF() {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/digitalsaude/contrato/procurarPorCpfTitular/${user.document}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetContratoDigitalSaudePorCodigo() {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/digitalsaude/contrato/procurarPorCodigo/${user.codigo}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetFaturaDigitalSaudePorContrato() {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/digitalsaude/fatura/procurarPorContrato/${user.codigo}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }

  GetDemonstrativoDigitalSaudePorContrato() {
    let user = JSON.parse(environment.storage.getItem('USER'));
    let ACCESSTOKENBASE = JSON.parse(environment.storage.getItem('ACCESSTOKENBASE'));
    const httpOptions = {
      headers: new HttpHeaders({
        'x-access-token': ACCESSTOKENBASE,
      }),
    };

    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlan}/digitalsaude/demonstrativo/procurarPorContrato/${user.codigo}`,
        httpOptions
      )
      .pipe(tap(async (res: any) => { }));
  }


  GetContatos(codigo?) {
    return this.httpClient
      .get(
        `${environment.apiBaseEasyPlanClientes}/contato/${codigo ? codigo : ''}`,
      )
      .pipe(tap(async (res: any) => { }));
  }
}



// <input type="checkbox" name="idStatusFaturaArray" value="1" class="idStatusFaturaArray">&nbsp;&nbsp;Provisória
// <input type="checkbox" name="idStatusFaturaArray" value="2" class="idStatusFaturaArray">&nbsp;&nbsp;Emitida
// <input type="checkbox" name="idStatusFaturaArray" value="3" class="idStatusFaturaArray">&nbsp;&nbsp;Paga
// <input type="checkbox" name="idStatusFaturaArray" value="4" class="idStatusFaturaArray">&nbsp;&nbsp;Vencida
// <input type="checkbox" name="idStatusFaturaArray" value="5" class="idStatusFaturaArray">&nbsp;&nbsp;Cancelada
// <input type="checkbox" name="idStatusFaturaArray" value="6" class="idStatusFaturaArray">&nbsp;&nbsp;Baixada
// <input type="checkbox" name="idStatusFaturaArray" value="7" class="idStatusFaturaArray">&nbsp;&nbsp;Paga no Cartão
// <input type="checkbox" name="idStatusFaturaArray" value="8" class="idStatusFaturaArray">&nbsp;&nbsp;Parcelada
// <input type="checkbox" name="idStatusFaturaArray" value="9" class="idStatusFaturaArray">&nbsp;&nbsp;Reemitida